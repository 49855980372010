@import './src/index.scss';

.main__error {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: $color-link-red;
    @media screen and (max-width: 430px) {
      gap: 113px;
      margin-top: 113px;
    }
  
    .error__404 {
      font-size: 17rem;
      font-weight: 700;
      @media screen and (max-width: 768px) {
        font-size: 12rem;
      }
      @media screen and (max-width: 430px) {
        font-size: 6rem;
      }
    }
  
    .error__txt {
      font-size: 2.5rem;
      font-weight: 500;
      padding: 50px 0 100px;
      text-align: center;
      @media screen and (max-width: 430px) {
        padding: 0;
        font-size: $font19px;
        max-width: 55%;
      }
    }
      
    @media screen and (max-width: 430px) {
      a{
      font-size: 0.9rem;
      }
    }
    
  }