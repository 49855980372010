@import './src/index.scss';

.banner {
  margin: auto;
  max-width: $max-width1240;
  position: relative;
  height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.25rem;
  @media screen and (max-width: 430px) {
    max-width: $max-width335;
    height: 111px;
    font-size: 0.625rem;
    justify-content: left;
  }

  &__text {
    position: relative;
    z-index: 2;
    @media screen and (max-width: 430px) {
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 210px;
    padding-left: 15px;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    @media screen and (max-width: 430px) {
      border-radius: 10px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    @media screen and (max-width: 430px) {
      border-radius: 10px;
    }
  }
}
