@import './src/index.scss';

.collapse {
    max-width: $max-width1240;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 430px) {
        max-width: $max-width335;
        margin-top: 19px;
        gap: 20px;
    }
}