@import './src/index.scss';

footer {
  margin-top: 50px;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: $color-link-black;
  color: $color-link-white; 
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  @media screen and (max-width: 430px) {
    height: 150px;
    margin-top: 0;
    justify-content: center;
    gap: 10px;
  }

  p {
    @media screen and (max-width: 430px) {
      white-space: pre-line;
      overflow-wrap: break-word;
      max-width: 95px;
      font-size: 0.75rem;
      }
  }

  .footer__logo {
  width: 122px;
  }
}