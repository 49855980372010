@import './src/index.scss';

.card {
  width: 340px;
  height: 340px;
  margin: 25px 30px;
  position: relative;
  @media screen and (max-width: 430px) {
    width: 335px;
    height: 255px;
    margin: 10px 0;
  }
  
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    @media screen and (max-width: 430px) {
    
    }
  }

  &__title {
    font-weight: 700;
    width: 100%;
    height: 52px;
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 0.938rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;
  }
}