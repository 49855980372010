@import './src/index.scss';

.rating {
  text-align: right;
  @media screen and (max-width: 768px) {
    text-align: center;
  }

  .stars {
    font-size: 1.5rem;
    color: $color-link-gray;
    @media screen and (max-width: 430px) {
      font-size: $font18px;
    }
  }

  .star {
    transition: color 0.3s ease;
  }

  .full-star,
  .half-star {
    color: $color-link-red;
  }

  .empty-star {
    color: $color-link-gray;
  }
}