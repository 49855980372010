@import './src/index.scss';

.logements__card {
    margin: auto;
    max-width: $max-width1240;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $color-link-light-gray;
    border-radius: 25px;
    margin-top: 50px;
    padding: 25px 0;
    @media screen and (max-width: 430px) {
      max-width: $max-width335;
      background-color: $color-link-white;
      margin-top: 10px;
      padding: 0;
      border-radius: 10px;
    }
  }
