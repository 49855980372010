// Import des polices et des mixins si nécessaire
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

// Définition des variables
$font-family: 'Montserrat', sans-serif;
$color-link-black: black;
$color-link-red: #FF6060;
$color-link-gray: #E3E3E3;
$color-link-white: #fff;
$color-link-light-gray: #F6F6F6;
$max-width1240: 1240px;
$max-width335: 335px;
$font19px : 1.2rem;
$font18px : 1.125rem;
$font14px : 0.875rem;

// Reset CSS
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-family
}

// Styles généraux
a {
    color: $color-link-black;
}

li {
    list-style-type: none;
}

// Contenu principal
#root {
    position: relative;
    min-height: 100vh;
    padding-bottom: 259px;
    @media screen and (max-width: 430px) {
        padding-bottom: 160px;
    }
}