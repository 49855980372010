@import './src/index.scss';

.carousel__container {
  position: relative;
  width: 100%;
  height: 415px;
  overflow: hidden;
  border-radius: 10px;
  @media screen and (max-width: 430px) {
    height: 255px;
  }

  .carousel__pic {
    width: 100%;
    height: 415px;
    object-fit: cover;
    @media screen and (max-width: 430px) {
      height: 255px;
    }
  }

  .courousel__right__chevron,
  .courousel__left__chevron {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 5rem;
    color: #fff;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    @media screen and (max-width: 430px) {
      font-size: 1.5rem;
    }

    &.courousel__right__chevron {
      right: 32px;
      @media screen and (max-width: 430px) {
        right: 10px;
      }
    }

    &.courousel__left__chevron {
      left: 32px;
      @media screen and (max-width: 430px) {
        left: 10px;
      }
    }
  }

  .slide {
    opacity: 0;
    transition-duration: 1s ease;

    &.active {
      opacity: 1;
      transition-duration: 1s;
      transform: scale(1.08);
    }
  }

  .carousel__page-number {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: $font19px;
    color: #fff;
    user-select: none;
    @media screen and (max-width: 430px) {
      opacity: 0;
    }
  }
}