@import './src/index.scss';

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.collapse {
  
  &__list {
    width: 83%;
    @media screen and (max-width: 430px) {
      width: 100%;
    }
  }

  &__header {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: $color-link-red;
    padding: 10px 20px 10px 15px;
    color: $color-link-white;
    border-radius: 5px;
    cursor: pointer;

    h3 {
      font-size: 1.5rem;
      @media screen and (max-width: 430px) {
        font-size: 0.815rem;
      }
    }
  }

  &__icon {
    transition: transform 0.3s ease;
    width: 32px;
    height: 32px;
    @media screen and (max-width: 430px) {
      width: 19px;
      height: 19px;
    }

    &.open {
      transform: rotate(-180deg);
    }
  }

  &__content {
    background: rgba(246, 246, 246, 0.45);
    padding: 10px 20px 10px 15px;
    font-size: $font18px;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    animation: slideDown 0.8s ease forwards;
    @media screen and (max-width: 430px) {
      font-size: 0.75rem;
    }

    p {
      animation-delay: 0.4s;
      animation: fadeIn 0.4s ease forwards;
    }
  }
}